.common_tree_handle[data-v-0e2d5992] {
  min-width: 188px;
}
.input-new-tag[data-v-0e2d5992] {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
[data-v-0e2d5992] .el-form-item__content .el-tag {
  height: 32px;
  line-height: 32px;
  margin-right: 12px;
  margin-bottom: 5px;
}
[data-v-0e2d5992] .el-form-item__content .el-tag .el-icon-close {
  font-size: 16px;
}
.left-content[data-v-0e2d5992] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
}
.left-content .content[data-v-0e2d5992] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.left-content .content .img-content[data-v-0e2d5992] {
  width: 188px;
  padding: 3px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 12px;
}
.left-content .content .img-content img[data-v-0e2d5992] {
  width: 188px;
  height: 100px;
}
.left-content .content .img-content .delete-icon[data-v-0e2d5992] {
  position: absolute;
  top: -6px;
  right: -6px;
  font-size: 16px;
  color: red;
}
.left-content .content .img-content .file-name[data-v-0e2d5992] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.formTopicCourse[data-v-0e2d5992] {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-weight: 600 !important;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.formTopic[data-v-0e2d5992] {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.shuttleBackBox[data-v-0e2d5992] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.shutleBack[data-v-0e2d5992] {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}
.leftRightBtn[data-v-0e2d5992] {
  margin: 211px 12px;
}
.shutleTitle[data-v-0e2d5992] {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}
.shutContent[data-v-0e2d5992] {
  height: 338px;
  overflow: auto;
}
.deptBox[data-v-0e2d5992] {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.deptBox .unitTitle[data-v-0e2d5992] {
  font-size: 13px;
}
.unitBox[data-v-0e2d5992] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}
.unitTitle[data-v-0e2d5992] {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}
[data-v-0e2d5992] .el-checkbox-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
}
[data-v-0e2d5992] .el-checkbox {
  margin-right: 5px !important;
}
.paperDialog[data-v-0e2d5992] .el-dialog__body {
  padding: 10px;
}
.knowledge[data-v-0e2d5992] .el-dialog__body {
  padding-top: 20px !important;
}
.paperDialog[data-v-0e2d5992] .el-table__fixed-header-wrapper .el-checkbox__inner {
  display: none !important;
}
.knowledge[data-v-0e2d5992] .el-table__fixed-header-wrapper .el-checkbox__inner {
  display: block !important;
}
.paperDialog[data-v-0e2d5992] #gridLayout {
  height: calc(100% - 56px);
}
.paperDialog[data-v-0e2d5992] .el-tree {
  height: calc(100% - 56px) !important;
}
.paperDialog[data-v-0e2d5992] .el-table {
  height: 392px !important;
}
.paperDialog[data-v-0e2d5992] .el-table__body-wrapper {
  max-height: 358px !important;
  overflow-y: auto;
}
.paperDialog[data-v-0e2d5992] .el-container {
  height: 500px !important;
}
.dialogBtn[data-v-0e2d5992] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.stareBox[data-v-0e2d5992] {
  position: absolute;
  color: #f56c6c;
  left: -134px;
}
.fileBigBox[data-v-0e2d5992] {
  width: 100%;
  max-height: 300px;
  min-height: 100px;
  overflow: auto;
  border-top: 1px solid #bbbbbb;
  margin-top: 12px;
}
.fileBox[data-v-0e2d5992] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #bbbbbb;
}
.fileBox div[data-v-0e2d5992] {
  width: 25%;
  text-align: center;
  font-size: 14px;
  border-right: 1px solid #bbbbbb;
}
.fileName[data-v-0e2d5992] {
  border-left: 1px solid #bbbbbb;
  padding: 0 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.fileName span[data-v-0e2d5992]:hover {
  text-decoration: underline;
  cursor: pointer;
}
.fileType[data-v-0e2d5992] {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 6px;
}
.fileOperate[data-v-0e2d5992] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.fileOperate span[data-v-0e2d5992] {
  cursor: pointer;
  margin: 0 6px;
}
[data-v-0e2d5992] .UserDeptMultipleDialog .avue-crud .el-table {
  height: calc(100vh - 384px) !important;
  max-height: calc(100vh - 384px) !important;
}
[data-v-0e2d5992] .UserDeptMultipleDialog .avue-tree__content {
  height: calc(100vh - 350px);
}
[data-v-0e2d5992] .UserDeptMultipleDialog .el-tree {
  height: calc(100vh - 374px) !important;
}
[data-v-0e2d5992] .courseSelection .avue-crud .el-table {
  height: calc(100vh - 460px) !important;
  max-height: calc(100vh - 460px) !important;
}
[data-v-0e2d5992] .courseSelection .el-tree {
  height: calc(100vh - 460px) !important;
}